const storageKeyCredentials = 'credentials';

class CredentialsStore {
  encodeBasicAuthCredentials(username: string, password: string) {
    return btoa(`${username}:${password}`);
  }

  storeBasicAuthCredentials(username: string, password: string) {
    sessionStorage.setItem(storageKeyCredentials, this.encodeBasicAuthCredentials(username, password));
  }

  retrieveBasicAuthCredentials() {
    return sessionStorage.getItem(storageKeyCredentials);
  }

  isLoggedIn() {
    return this.retrieveBasicAuthCredentials() !== null;
  }
}

export const credentialsStore = new CredentialsStore();
