import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import logo from '../images/Logo.svg';
import { CircularProgress, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

interface HeaderProps {
  showMobileTextArea: boolean;
  onBackButtonClick: () => void;
  isDisabled: boolean;
}

function MobileHeader({ showMobileTextArea, onBackButtonClick, isDisabled }: HeaderProps) {
  return (
    <>
      {showMobileTextArea ? (
        <AppBar
          position="sticky"
          color="default"
          elevation={0}
          sx={{
            backgroundColor: '#FFFFFF',
            borderBottom: theme => `1px solid ${theme.palette.divider}`,
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}>
            <div style={{ flex: 1, textAlign: 'center' }}>
              <Typography
                variant="h6"
                component="div"
                sx={{ fontSize: '16px', lineHeight: '28px', marginLeft: isDisabled ? '50%' : '15%' }}>
                Result
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              disableElevation={true}
              disabled={isDisabled}
              onClick={onBackButtonClick}
              sx={{
                textTransform: 'none',
                backgroundColor: '#C80000',
                padding: '6px',
                height: '36px',
                width: isDisabled ? '40%' : '',
                gap: '4px',
                borderRadius: '0.5',
                position: 'relative',
                display: 'flex',
              }}
              data-testid="get-started-button">
              <span style={{ marginLeft: isDisabled ? '20px' : '0' }}>{isDisabled ? 'Generating text' : 'Edit'}</span>
              {isDisabled && (
                <CircularProgress
                  size={17}
                  sx={{ position: 'absolute', left: '8px', top: '50%', marginTop: '-9px', color: '#fff' }}
                />
              )}
            </Button>
          </Toolbar>
        </AppBar>
      ) : (
        <AppBar
          position="sticky"
          color="default"
          elevation={0}
          sx={{
            backgroundColor: '#FFFFFF',
            borderBottom: theme => `1px solid ${theme.palette.divider}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Toolbar sx={{ justifyContent: 'center' }}>
            <Link to="/welcome">
              <img
                src={logo}
                style={{ height: '45px', padding: '0', marginBottom: '0%', marginLeft: '-50%' }}
                alt="Logo"
              />
            </Link>

            <Grid
              item
              xs={12}
              sx={{ display: 'flex', marginLeft: '-200px', justifyContent: 'center', flexGrow: 1 }}></Grid>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}

export default MobileHeader;
