import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { Divider, Paper, Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import refresh from '../images/refresh.svg';
import copy from '../images/copy.svg';
import { Input } from '@mui/material';
import { Hit } from './Hit';
import {
  copyButtonstyles,
  gridStyle,
  gridStyles,
  iconStyles,
  inputStyles,
  paperStyles,
  refreshButtonStyles,
  typoStyles,
} from './styles/TextAreaStyles';
import { credentialsStore } from '../components/Login/credentialsStore';
import { fetchEventSourceWithAuth } from './fetchEventSourceWithAuth';
import { algolia, headlineList, objectDescriptionList, streamTextGeneration } from './helpers';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../redux/store';
import { setAPIObj } from '../redux/slices/apiSlice';
interface Idprop {
  isFirstTime: boolean;
  enteredId: string;
  isDisabled: boolean;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}
const TextArea: React.FC<Idprop> = ({ isFirstTime, enteredId, isDisabled, setIsDisabled }) => {
  const [copiedHeadline, setCopiedHeadline] = React.useState(false);
  const [copiedObject, setCopiedObject] = React.useState(false);
  const [copiedLocation, setCopiedLocation] = React.useState(false);
  const [focused, setFocused] = useState(false);
  const [focused2, setFocused2] = useState(false);
  const [focused3, setFocused3] = useState(false);
  const [headline, setHeadline] = React.useState('');
  const [objectDescription, setObjectDescription] = React.useState('');
  const [locationDescription, setLocationDescription] = React.useState('');
  const [oldLocationDescription, setOldLocationDescription] = React.useState('');
  const [oldObjectDescription, setOldObjectDescription] = React.useState('');
  const [oldHeadline, setOldHeadline] = React.useState('');

  const [isDataFetched, setIsDataFetched] = useState(false);

  const dispatch = useDispatch();
  const searchResult = useSelector((state: ReduxState) => state.api.apiObj);
  const setSearchResult = (value: Hit | null) => dispatch(setAPIObj(value));
  function generateLocationString() {
    const toBeUsedList = [];
    for (const key in searchResult?.locationObj?.places_by_features) {
      if (searchResult?.locationObj?.places_by_features[key] && !searchResult?.deleted.includes(key)) {
        toBeUsedList.push(key);
      }
    }

    let toBeUsedStr = '';
    for (const key of toBeUsedList) {
      toBeUsedStr += `${key}, `;
    }

    toBeUsedStr = toBeUsedStr.slice(0, -2);

    const toNotBeUsedList = [];
    for (const key in searchResult?.locationObj?.places_by_features) {
      if (searchResult?.locationObj?.places_by_features[key] && searchResult?.deleted.includes(key)) {
        toNotBeUsedList.push(key);
      }
    }

    let toNotBeUsedStr = '';
    for (const key of toNotBeUsedList) {
      toNotBeUsedStr += `${key}, `;
    }

    toNotBeUsedStr = toNotBeUsedStr.slice(0, -2);

    const str =
      toBeUsedStr +
      ' , additionalKeywords: ' +
      searchResult?.locationAdditionalKeywords +
      ' , do-not-use: ' +
      toNotBeUsedStr;

    return str;
  }

  function generateObjectString() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const toBeUsedObj: { [key: string]: any } = {};
    for (const key in searchResult) {
      if (objectDescriptionList.includes(key) && searchResult[key] && !searchResult?.deleted.includes(key)) {
        toBeUsedObj[key] = searchResult[key];
      }
    }

    let toBeUsedStr = '';
    for (const key in toBeUsedObj) {
      toBeUsedStr += `${key}:${toBeUsedObj[key]}, `;
    }

    toBeUsedStr = toBeUsedStr.slice(0, -2);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const toNotBeUsedObj: { [key: string]: any } = {};
    for (const key in searchResult) {
      if (objectDescriptionList.includes(key) && searchResult?.deleted.includes(key)) {
        toNotBeUsedObj[key] = searchResult[key];
      }
    }

    let toNotBeUsedStr = '';
    for (const key in toNotBeUsedObj) {
      toNotBeUsedStr += `${key}:${toNotBeUsedObj[key]}, `;
    }

    toNotBeUsedStr = toNotBeUsedStr.slice(0, -2);

    const str =
      toBeUsedStr +
      ' , additionalKeywords: ' +
      searchResult?.objectAdditionalKeywords +
      ' , do-not-use: ' +
      toNotBeUsedStr;

    return str;
  }
  function generateHeadlineString() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const toBeUsedObj: { [key: string]: any } = {};
    for (const key in searchResult) {
      if (headlineList.includes(key) && searchResult[key] && !searchResult?.deleted.includes(key)) {
        toBeUsedObj[key] = searchResult[key];
      }
    }

    let toBeUsedStr = '';
    for (const key in toBeUsedObj) {
      toBeUsedStr += `${key}:${toBeUsedObj[key]}, `;
    }

    toBeUsedStr = toBeUsedStr.slice(0, -2);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const toNotBeUsedObj: { [key: string]: any } = {};
    for (const key in searchResult) {
      if (headlineList.includes(key) && searchResult?.deleted.includes(key)) {
        toNotBeUsedObj[key] = searchResult[key];
      }
    }

    let toNotBeUsedStr = '';
    for (const key in toNotBeUsedObj) {
      toNotBeUsedStr += `${key}:${toNotBeUsedObj[key]}, `;
    }

    toNotBeUsedStr = toNotBeUsedStr.slice(0, -2);

    const str =
      toBeUsedStr +
      ' , additionalKeywords: ' +
      searchResult?.objectAdditionalKeywords +
      ' , do-not-use: ' +
      toNotBeUsedStr;

    return str;
  }
  useEffect(() => {
    const generateData = async (inputHeadline: string, inputObject: string, inputLocation: string) => {
      await Promise.all([
        handleHeadlineGenerationPromise(inputHeadline),
        handleObjectGenerationPromise(inputObject),
        handleLocationGenerationPromise(inputLocation),
      ]);
      setIsDisabled(false);
    };

    if (searchResult && isDataFetched && isFirstTime) {
      const inputHeadline = generateHeadlineString();
      const inputObject = generateObjectString();
      const inputLocation = generateLocationString();
      generateData(inputHeadline, inputObject, inputLocation);
    }
  }, [searchResult]);

  const didRun = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsDisabled(true);
      if (enteredId) {
        setIsDataFetched(true);
        const { temp } = await algolia(enteredId);
        setSearchResult(temp);
      }
    };

    if (!didRun.current) {
      fetchData();
      didRun.current = true;
    }
  }, [enteredId, setSearchResult, setIsDisabled, setIsDataFetched, credentialsStore]);
  function handleHeadlineGenerationPromise(inputValue: string) {
    return streamTextGeneration('/api/headlineFormatted', setHeadline, setOldHeadline, inputValue);
  }
  function handleObjectGenerationPromise(inputValue: string) {
    return streamTextGeneration('/api/objectFormatted', setObjectDescription, setOldObjectDescription, inputValue);
  }
  function handleLocationGenerationPromise(inputValue: string) {
    return streamTextGeneration(
      '/api/locationFormatted',
      setLocationDescription,
      setOldLocationDescription,
      inputValue
    );
  }
  async function streamGeneration(
    url: string,
    setInput: React.Dispatch<React.SetStateAction<string>>,
    setOldInput: React.Dispatch<React.SetStateAction<string>>,
    context: string,
    generator: () => string
  ) {
    setIsDisabled(true);
    const input = generator() + '--' + context;

    setInput('');
    await fetchEventSourceWithAuth(url, {
      method: 'POST',
      headers: { Accept: 'text/event-stream' },
      body: JSON.stringify({ query: input }),
      onmessage: (ev: MessageEvent) => {
        setInput(prevState => prevState + ev.data);
      },
    }).then(() => {
      setOldInput(headline);
      setIsDisabled(false);
    });
  }
  function handleHeadlineGeneration() {
    const context =
      '<write here>\n' +
      (objectDescription || oldObjectDescription) +
      '\n' +
      (locationDescription || oldLocationDescription);
    return streamGeneration('/api/headline', setHeadline, setOldHeadline, context, generateHeadlineString);
  }
  function handleObjectGeneration() {
    const context =
      (headline || oldHeadline) + '\n' + '<write here>\n' + (locationDescription || oldLocationDescription);
    return streamGeneration(
      '/api/object',
      setObjectDescription,
      setOldObjectDescription,
      context,
      generateObjectString
    );
  }
  function handleLocationGeneration() {
    const context =
      (headline || oldHeadline) + '\n' + (objectDescription || oldObjectDescription) + '\n' + '<write here>';
    return streamGeneration(
      '/api/location',
      setLocationDescription,
      setOldLocationDescription,
      context,
      generateLocationString
    );
  }
  function handleCopy(ref: string) {
    if (ref == 'headline') {
      navigator.clipboard.writeText(headline);
      setCopiedHeadline(true);
      setTimeout(() => setCopiedHeadline(false), 1500);
    }
    if (ref == 'object') {
      navigator.clipboard.writeText(objectDescription);
      setCopiedObject(true);
      setTimeout(() => setCopiedObject(false), 1500);
    }
    if (ref == 'location') {
      navigator.clipboard.writeText(locationDescription);
      setCopiedLocation(true);
      setTimeout(() => setCopiedLocation(false), 1500);
    }
  }
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 616));

  const setSx = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 1000));
  return (
    <Grid sx={{ minHeight: '90vh' }}>
      <Grid item xs={12} sx={{ ...gridStyles, mt: isSmallScreen ? '30px' : '' }}>
        <Typography sx={{ ...typoStyles, marginBottom: '6.5px' }}>Headline</Typography>

        <Box border="1px solid #F0F0F0" borderRadius="4px" padding="0px" marginBottom="0px" boxShadow={0}>
          <div>
            <Input
              disableUnderline={true}
              style={inputStyles}
              placeholder={!headline && !focused ? 'Results' : ''}
              multiline
              rows={1}
              value={headline}
              fullWidth
              onChange={e => setHeadline(e.target.value)}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
            <Divider sx={{ bgcolor: '#f0f0f0' }} />
            <Paper sx={paperStyles}>
              <Button disabled={isDisabled} onClick={handleHeadlineGeneration} sx={refreshButtonStyles(isDisabled)}>
                <img src={refresh} style={iconStyles} alt="Logo" />
              </Button>

              <Button onClick={() => handleCopy('headline')} sx={copyButtonstyles(copiedHeadline)}>
                {copiedHeadline ? 'COPIED!' : 'Copy'}
                <img src={copy} style={iconStyles} alt="Logo" />
              </Button>
            </Paper>
          </div>
        </Box>
      </Grid>
      <Grid container={setSx ? false : true} item={setSx ? true : false} spacing={4}>
        <Grid item xs={setSx ? 12 : 6} sx={gridStyle}>
          <Typography sx={typoStyles} gutterBottom>
            Object
          </Typography>

          <Box border="1px solid #F0F0F0" borderRadius="4px" padding="0px" marginBottom="0px">
            <div>
              <Input
                disableUnderline={true}
                style={inputStyles}
                placeholder={!objectDescription && !focused2 ? 'Results' : ''}
                multiline
                rows={isSmallScreen || setSx ? 5 : 17}
                value={objectDescription}
                fullWidth
                onChange={e => setObjectDescription(e.target.value)}
                onFocus={() => setFocused2(true)}
                onBlur={() => setFocused2(false)}
              />
              <Divider sx={{ bgcolor: '#f0f0f0' }} />
              <Paper sx={paperStyles}>
                <Button disabled={isDisabled} onClick={handleObjectGeneration} sx={refreshButtonStyles(isDisabled)}>
                  <img src={refresh} style={iconStyles} alt="Logo" />
                </Button>

                <Button onClick={() => handleCopy('object')} sx={copyButtonstyles(copiedObject)}>
                  {copiedObject ? 'COPIED!' : 'Copy'}
                  <img src={copy} style={iconStyles} alt="Logo" />
                </Button>
              </Paper>
            </div>
          </Box>
        </Grid>

        <Grid
          item
          xs={setSx ? 12 : 6}
          sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: '32px' }}>
          <Typography sx={{ ...typoStyles, mb: '6px' }}>Location</Typography>
          <Box border="1px solid #F0F0F0" borderRadius="4px" padding="0px" marginBottom="0px">
            <div>
              <Input
                style={inputStyles}
                disableUnderline={true}
                placeholder={!locationDescription && !focused3 ? 'Results' : ''}
                multiline
                rows={isSmallScreen || setSx ? 5 : 17}
                value={locationDescription}
                fullWidth
                onChange={e => setLocationDescription(e.target.value)}
                onFocus={() => setFocused3(true)}
                onBlur={() => setFocused3(false)}
              />
              <Divider sx={{ bgcolor: '#f0f0f0' }} />
              <Paper sx={paperStyles}>
                <Button disabled={isDisabled} onClick={handleLocationGeneration} sx={refreshButtonStyles(isDisabled)}>
                  <img src={refresh} style={iconStyles} alt="Logo" />
                </Button>

                <Button onClick={() => handleCopy('location')} sx={copyButtonstyles(copiedLocation)}>
                  {copiedLocation ? 'COPIED!' : 'Copy'}
                  <img src={copy} style={iconStyles} alt="Logo" />
                </Button>
              </Paper>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TextArea;
