import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CSSProperties } from 'react';

export function useResponsiveStyles() {
  const theme = useTheme();
  const isXSScreen = useMediaQuery(theme.breakpoints.between('xs', 600));
  const setPadding = useMediaQuery(theme.breakpoints.between(500, 600));
  const isXlScreen = useMediaQuery(theme.breakpoints.up('xl'));
  // const setResponsiveness = useMediaQuery(theme.breakpoints.between(600, 750));

  const gridStyle: CSSProperties = {
    background: '#FAFAFA',
    padding: '24px, 40px, 0px, 40px',
    position: 'relative',
    zIndex: '1',
  };

  const gridPadding: CSSProperties = {
    paddingLeft: isXSScreen && setPadding ? '30px' : '15px',
    paddingRight: isXSScreen && setPadding ? '30px' : '15px',
  };

  const dividerStyles: CSSProperties = {
    height: '100%',
    border: '1px solid #e0e0e0',
    width: '0',
    position: 'absolute',
    left: '0px',
    marginTop: '-1%',
    zIndex: 1,
  };

  const containerStyles: CSSProperties = {
    marginLeft: isXlScreen ? 'auto' : undefined,
    padding: isXlScreen ? 'auto' : undefined,
    height: 'auto',
  };

  return { gridStyle, gridPadding, dividerStyles, containerStyles };
}
