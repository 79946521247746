import { CircularProgress } from '@mui/material';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Divider, Theme, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import address from '../images/address.png';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Hit } from './Hit';
import {
  addressStyles,
  chipsStyles,
  circularStyle,
  dividerStyles,
  genButtonStyles,
  grid3Styles,
  gridStyles,
  infoButtonStyles,
  resetButtonStyles,
  stickyfooterStyles,
  subHeadingStyles,
  TextFieldStyles,
  typographyStyles,
} from './styles/PropFormStyles';
import ErrorTextArea from './ErrorTextArea';
import {
  algolia,
  locationCheckList,
  LocationKeyType,
  locationMapFromFieldToLabel,
  objectCheckList,
  objectMapFromFieldToLabel,
} from './helpers';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../redux/store';
import { setAPIObj } from '../redux/slices/apiSlice';
interface EVIDprop {
  setIsFirstTime: React.Dispatch<React.SetStateAction<boolean>>;
  enteredEVID: string;
  isErrorMain: boolean;
  setIsErrorMain: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled: boolean;
  onGenerateClick: () => void;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}
type ButtonInfo = {
  label: string;
  state: boolean;
  index: number;
};
const PropertyForm: React.FC<EVIDprop> = ({
  setIsFirstTime,
  enteredEVID,
  onGenerateClick,
  isErrorMain,
  setIsErrorMain,
  isDisabled,
  setIsDisabled,
}) => {
  const navigate = useNavigate();
  const [isGenerateContent, setIsGenerateContent] = useState(false);
  const [addressValue, setAddressValue] = useState('');
  const [locationAdditionalInputText, setLocationAdditionalInputText] = React.useState('');
  const [locationButtons, setLocationButtons] = React.useState<ButtonInfo[]>([]);
  const [objectAdditionalInputText, setObjectAdditionalInputText] = React.useState('');
  const [evId, setEvId] = React.useState(enteredEVID);
  const [objectButtons, setObjectButtons] = React.useState<ButtonInfo[]>([]);

  const dispatch = useDispatch();
  const searchResult = useSelector((state: ReduxState) => state.api.apiObj);
  const setSearchResult = (value: Hit | null) => dispatch(setAPIObj(value));
  const objectMapFromLabelToField: { [key: string]: string } = {};
  for (const key in objectMapFromFieldToLabel) {
    objectMapFromLabelToField[objectMapFromFieldToLabel[key]] = key;
  }
  const locationMapFromLabelToField: { [key: string]: string } = {};
  for (const key in locationMapFromFieldToLabel) {
    locationMapFromLabelToField[locationMapFromFieldToLabel[key as LocationKeyType]] = key;
  }
  useEffect(() => {
    if (!isGenerateContent) {
      const newobjectMapFromFieldToLabel: ButtonInfo[] = [];
      const newlocationMapFromFieldToLabel: ButtonInfo[] = [];
      if (searchResult) {
        for (const [index, key] of Array.from(objectCheckList.entries())) {
          if (searchResult[key]) {
            const value = objectMapFromFieldToLabel[key as keyof typeof objectMapFromFieldToLabel];
            const button = { label: value, state: true, index: index };
            newobjectMapFromFieldToLabel.push(button);
          }
        }
        for (const [index, key] of Array.from(locationCheckList.entries())) {
          if (searchResult?.locationObj?.places_by_features[key]) {
            const value = locationMapFromFieldToLabel[key as keyof typeof locationMapFromFieldToLabel];
            const button = { label: value, state: true, index: index };
            newlocationMapFromFieldToLabel.push(button);
          }
        }
        setAddressValue(searchResult?.locationObj?.address);
      }
      setObjectButtons(newobjectMapFromFieldToLabel);
      setLocationButtons(newlocationMapFromFieldToLabel);
    }
  }, [searchResult]);
  const handleObjectButtonClick = (index: number) => {
    setObjectButtons(prevButtons => {
      return prevButtons.map(button => {
        if (button.index === index) {
          return { ...button, state: !button.state };
        }
        return button;
      });
    });
  };
  const handleEvIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEvId(event.target.value);
  };
  const handleAdditionalObjectInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setObjectAdditionalInputText(event.target.value);
  };
  const loadNewPropertyData: () => void = async () => {
    try {
      if (evId.length != 0) {
        setIsGenerateContent(false);
        const { temp } = await algolia(evId);
        setIsFirstTime(false);
        setIsErrorMain(false);
        setSearchResult(temp);
      } else {
        alert('Enter an EV ID!');
      }
    } catch (err) {
      setIsErrorMain(true);
    }
  };

  const handleLocationButtonClick = (index: number) => {
    setLocationButtons(prevButtons => {
      return prevButtons.map(button => {
        if (button.index === index) {
          return { ...button, state: !button.state };
        }
        return button;
      });
    });
  };
  const handleAdditionalLocationInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocationAdditionalInputText(event.target.value);
  };
  const handleGenerateNewContent = () => {
    setIsGenerateContent(true);
    setIsDisabled(true);
    const selectedObjectButtonFields = objectButtons
      .filter(button => !button.state)
      .map(button => objectMapFromLabelToField[button.label]);
    const selectedLocationButtonFields = locationButtons.filter(button => !button.state).map(button => button.label);
    setIsFirstTime(true);
    if (searchResult) {
      const apiObjCopy = { ...searchResult };
      apiObjCopy.deleted = [];
      selectedObjectButtonFields.forEach(key => {
        apiObjCopy.deleted.push(key);
      });
      selectedLocationButtonFields.forEach(key => {
        apiObjCopy.deleted.push(key);
      });
      if (objectAdditionalInputText) {
        apiObjCopy['objectAdditionalKeywords'] = objectAdditionalInputText;
      } else {
        apiObjCopy['objectAdditionalKeywords'] = '';
      }
      if (objectAdditionalInputText) {
        apiObjCopy['locationAdditionalKeywords'] = locationAdditionalInputText;
      } else {
        apiObjCopy['locationAdditionalKeywords'] = '';
      }
      setSearchResult(apiObjCopy);
    }
  };

  const handleReset = () => {
    setObjectAdditionalInputText('');
    setLocationAdditionalInputText('');
    const updatedLocationButtons = locationButtons.map(button => ({
      ...button,
      state: true,
    }));

    setLocationButtons(updatedLocationButtons);

    const updatedObjectButtons = objectButtons.map(button => ({
      ...button,
      state: true,
    }));

    setObjectButtons(updatedObjectButtons);

    navigate('/welcome');
  };
  const addressParts = addressValue.split(', ');

  const setResponsiveness = useMediaQuery((theme: Theme) => theme.breakpoints.between(600, 750));
  const setResponsiveness1 = useMediaQuery((theme: Theme) => theme.breakpoints.between(600, 1100));
  const setResponsive = useMediaQuery((theme: Theme) => theme.breakpoints.between(750, 950));
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 617));
  const setMargin = useMediaQuery((theme: Theme) => theme.breakpoints.up(1935));
  const isXlScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
  const isMegaScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up(4000));
  return (
    <Grid>
      <Box
        sx={{
          marginLeft: isSmallScreen ? '' : setResponsiveness ? '0' : '18px',
          marginRight: isSmallScreen ? '' : setResponsiveness ? '0' : '18px',
          paddingLeft: setMargin ? '15%' : '',
          paddingRight: setMargin ? '15%' : '',
          minHeight: '95vh',
          mb: '50px',
        }}>
        <Typography variant="h5" sx={{ ...subHeadingStyles, marginBottom: '16px' }}>
          General Information
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              id="EV-ID"
              name="EV_ID"
              label="EV ID property"
              fullWidth
              autoComplete="EV_ID"
              variant="outlined"
              value={evId}
              onChange={handleEvIdChange}
              sx={TextFieldStyles}
            />
          </Grid>

          <Grid item xs={12} sx={grid3Styles}>
            <Button
              variant="contained"
              data-testid="get-info-button"
              sx={infoButtonStyles}
              onClick={loadNewPropertyData}>
              Get property information
            </Button>
          </Grid>
        </Grid>

        {isErrorMain ? (
          isSmallScreen ? (
            <ErrorTextArea />
          ) : (
            ''
          )
        ) : (
          <>
            <Grid container alignItems="center">
              <Grid
                item
                xs={12}
                sm={12}
                style={{ display: 'flex', alignItems: 'flex-start', marginLeft: '-0.7%', marginTop: '24px' }}>
                <img src={address} alt="Address" style={{ maxWidth: '70%', height: '110px', marginRight: '10px' }} />
                <div>
                  <Typography variant="body1" style={addressStyles}>
                    Address:
                  </Typography>
                  <Typography variant="body2">
                    {isSmallScreen
                      ? addressValue
                      : addressParts.map((part, index) => (
                          <React.Fragment key={part}>
                            {part}
                            {index === 1 && ','}
                            {index === 1 && <br />}
                            {index < addressParts.length - 1 && index !== 1 && ', '}
                          </React.Fragment>
                        ))}
                  </Typography>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ textAlign: 'left', mb: '0px', mt: isSmallScreen ? '25px' : '32px' }}>
              <Typography variant="h6" color="textSecondary" gutterBottom style={subHeadingStyles}>
                Object Information
              </Typography>
              <Divider style={dividerStyles} />

              <Typography variant="body1" style={typographyStyles}>
                Information from property, activate or deactivate to be considered in the description
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ ...gridStyles, marginBottom: isSmallScreen ? '' : '10px', mt: isSmallScreen ? '' : '5px' }}>
              {objectButtons
                .slice(0, 6)
                .sort((a, b) => a.label.length - b.label.length)
                .map(button => (
                  <Button
                    key={button.label}
                    variant="contained"
                    sx={chipsStyles(button)}
                    onClick={() => handleObjectButtonClick(button.index)}>
                    {button.label}
                  </Button>
                ))}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                marginTop: isSmallScreen ? '8px' : '',
              }}>
              <TextField
                label="Enter important keywords"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                sx={TextFieldStyles}
                value={objectAdditionalInputText}
                onChange={handleAdditionalObjectInput}
                inputProps={{ maxLength: 1200 }}
              />
              <Typography sx={{ mt: '1px', mr: '2px', color: 'text.secondary' }}>
                {objectAdditionalInputText.length}/1200
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} sx={{ textAlign: 'left', mb: '0px', mt: isSmallScreen ? '12px' : '22px' }}>
                <Typography variant="h6" color="textSecondary" gutterBottom style={subHeadingStyles}>
                  Location Details
                </Typography>
                <Divider style={dividerStyles} />
              </Grid>
              <Typography variant="body1" style={{ ...typographyStyles, marginBottom: isSmallScreen ? '8px' : '' }}>
                Additional input for location description.
              </Typography>
              <Grid item xs={12} sx={{ ...gridStyles, mt: isSmallScreen ? '' : '8px' }}>
                {locationButtons
                  .slice(0, 6)
                  .sort((a, b) => a.label.length - b.label.length)
                  .map(button => (
                    <Button
                      key={button.label}
                      variant="contained"
                      sx={chipsStyles(button)}
                      onClick={() => handleLocationButtonClick(button.index)}>
                      {button.label}
                    </Button>
                  ))}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  mt: isSmallScreen ? '2px' : '10px',
                }}>
                <TextField
                  label="Enter important keywords"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  sx={TextFieldStyles}
                  value={locationAdditionalInputText}
                  onChange={handleAdditionalLocationInput}
                  inputProps={{ maxLength: 1200 }}
                />
                <Typography sx={{ mt: '3px', mr: '2px', alignSelf: 'flex-end', color: 'text.secondary' }}>
                  {locationAdditionalInputText.length}/1200
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
      {isErrorMain ? (
        ''
      ) : (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          xs={isSmallScreen ? 14.35 : setResponsiveness ? 14.3 : setResponsive ? 14 : 13.24}
          sx={{
            ...stickyfooterStyles,
            marginLeft: isSmallScreen ? '-10%' : setResponsiveness ? '-9.9%' : setResponsive ? '-8.2%' : '-3.9%',
            mb: '-0px',
            paddingLeft:
              isSmallScreen || setResponsiveness1
                ? '20px'
                : isXlScreen
                ? setMargin
                  ? isMegaScreen
                    ? '100px'
                    : '45px'
                  : '25px'
                : '20px',
            paddingRight:
              isSmallScreen || setResponsiveness1
                ? '20px'
                : isXlScreen
                ? setMargin
                  ? isMegaScreen
                    ? '100px'
                    : '55px'
                  : '35px'
                : '20px',
          }}>
          <Button variant="outlined" sx={resetButtonStyles} onClick={handleReset}>
            Restart
          </Button>
          <Button
            disabled={!isSmallScreen && isDisabled}
            variant="contained"
            disableElevation={true}
            sx={genButtonStyles}
            onClick={
              isSmallScreen
                ? () => {
                    handleGenerateNewContent && handleGenerateNewContent();
                    onGenerateClick && onGenerateClick();
                  }
                : handleGenerateNewContent
            }>
            {isDisabled && !isSmallScreen && <CircularProgress size={24} sx={circularStyle} />}
            <span style={{ marginLeft: isSmallScreen ? '' : isDisabled ? '20px' : '0' }}>
              {isSmallScreen ? 'Generate Content' : 'Regenerate Content'}
            </span>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default PropertyForm;
