import React from 'react';
import { fetchEventSourceWithAuth } from './fetchEventSourceWithAuth';
import axios from 'axios';
import { credentialsStore } from '../components/Login/credentialsStore';

export const headlineList = [
  'city_DE',
  'constructionYear',
  'district',
  'hasBalcony',
  'hasGarden',
  'hasSeaOrLakeView',
  'hasSwimmingPool',
  'hasTennisCourt',
  'hasWaterfront',
  'objectSubType',
  'plotSurface_AC',
];
export const objectDescriptionList = [
  'city_DE',
  'condition',
  'constructionYear',
  'district',
  'firePlace',
  'floors',
  'garages',
  'hasAirConditioning',
  'hasBalcony',
  'hasBasement',
  'hasBuiltInKitchen',
  'hasGarden',
  'hasPatio',
  'hasSeaOrLakeView',
  'hasSecuritySystem',
  'hasSwimmingPool',
  'hasTennisCourt',
  'hasTerrace',
  'hasWaterfront',
  'objectSubType',
  'objectType',
  'parkingBays',
  'plotSurface_AC',
  'plotSurface_HA',
  'plotSurface_SQFT',
  'plotSurface_SQMT',
];
export const objectMapFromFieldToLabel: { [key: string]: string } = {
  firePlace: 'Fireplace',
  floors: 'Floors',
  hasGarage: 'Garage',
  hasAirConditioning: 'Air-Conditioning',
  hasBalcony: 'Balcony',
  hasBasement: 'Basement',
  hasBuiltInKitchen: 'Built-in Kitchen',
  hasGarden: 'Garden',
  hasPatio: 'Patio',
  hasSeaOrLakeView: 'Sea/Lake View',
  hasSecuritySystem: 'Security System',
  hasSwimmingPool: 'Swimming Pool',
  hasTennisCourt: 'Tennis Court',
  hasTerrace: 'Terrace',
  hasWaterfront: 'Water Front',
};

export const locationCheckList = [
  'bus_station',
  'subway_station',
  'light_rail_station',
  'cafe',
  'restaurant',
  'store',
  'supermarket',
  'shopping_mall',
  'school',
  'library',
  'museum',
  'university',
  'tourist_attraction',
  'park',
  'parking',
] as const;

export type LocationKeyType = (typeof locationCheckList)[number];

export const locationMapFromFieldToLabel: { [K in LocationKeyType]: string } = {
  bus_station: 'Bus Station',
  subway_station: 'Subway Station',
  light_rail_station: 'Light Rail Station',
  cafe: 'Café',
  restaurant: 'Restaurant',
  shopping_mall: 'Shopping Mall',
  store: 'Store',
  supermarket: 'Supermarket',
  library: 'Library',
  museum: 'Musuem',
  park: 'Park',
  parking: 'Parking',
  school: 'School',
  tourist_attraction: 'Tourist Attraction',
  university: 'University',
};

export const objectCheckList = [
  'hasAirConditioning',
  'hasBalcony',
  'hasBasement',
  'hasBuiltInKitchen',
  'hasGarage',
  'hasGarden',
  'hasPatio',
  'hasSeaOrLakeView',
  'hasSecuritySystem',
  'hasSwimmingPool',
  'hasTennisCourt',
  'hasTerrace',
  'hasWaterfront',
];

export async function streamTextGeneration(
  url: string,
  setInput: React.Dispatch<React.SetStateAction<string>>,
  setOldInput: React.Dispatch<React.SetStateAction<string>>,
  input: string
) {
  setInput('');
  return fetchEventSourceWithAuth(url, {
    method: 'POST',
    headers: { Accept: 'text/event-stream', Authorization: `Basic ${credentialsStore.retrieveBasicAuthCredentials()}` },
    body: JSON.stringify({ query: input }),
    onmessage(ev: MessageEvent) {
      setInput(prevState => prevState + ev.data);
    },
  }).then(() => setOldInput(input));
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const algolia = async (enteredId: string): Promise<{ temp: any }> => {
  const data = { enteredId };

  let hitArray = [];
  const response = await axios({
    method: 'post',
    url: '/api/algolia',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${credentialsStore.retrieveBasicAuthCredentials()}`,
    },
    data,
  });

  const hits = response.data;

  hitArray = hits;
  if (hitArray.length == 0) {
    throw 'Nothing found!';
  }
  const temp = hits[0];
  const lat = temp?._geoloc?.lat;
  const lng = temp?._geoloc?.lng;
  const response1 = await axios.post('/api/send-place-id', {
    latlong: { lat: lat, long: lng },
  });
  const data1 = response1.data;
  temp['locationObj'] = data1;

  temp['deleted'] = [];
  return { temp };
};
