import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Hit } from '../../expose_co_pilot/Hit';

interface ApiState {
  apiObj: Hit | null;
}

const initialState: ApiState = {
  apiObj: null,
};

const apiSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setAPIObj: (state, action: PayloadAction<Hit | null>) => {
      state.apiObj = action.payload;
    },
  },
});

export const { setAPIObj } = apiSlice.actions;

export default apiSlice.reducer;
