import { type Action, configureStore, type ThunkAction } from '@reduxjs/toolkit';
import { useDispatch as useReduxDispatch } from 'react-redux';

import { reducer } from './rootReducer';
export const reduxStore = configureStore({
  reducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware();
  },
});
export const useDispatch = () => useReduxDispatch<ReduxDispatch>();

export type ReduxState = ReturnType<typeof reduxStore.getState>;
export type ReduxDispatch = typeof reduxStore.dispatch;
export type ReduxThunkAction<ReturnType = void> = ThunkAction<ReturnType, ReduxState, unknown, Action>;
