import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import WelcomePage from './expose_co_pilot/WelcomePage';
import Main from './expose_co_pilot/Main';
import Login from './components/Login/Login';
import { credentialsStore } from './components/Login/credentialsStore';

function App() {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(credentialsStore.isLoggedIn());
  useEffect(() => {
    const onStorage = () => {
      setIsUserLoggedIn(credentialsStore.isLoggedIn());
    };

    window.addEventListener('storage', onStorage);

    return () => {
      window.removeEventListener('storage', onStorage);
    };
  }, []);
  return (
    <Router>
      <Routes>
        {!isUserLoggedIn ? (
          <Route path="*" element={<Login />} />
        ) : (
          <Fragment>
            <Route path="/" element={<WelcomePage />} />
            <Route path="/welcome" element={<WelcomePage />} />
            <Route path="/expose" element={<Main />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Fragment>
        )}
      </Routes>
    </Router>
  );
}

export default App;
