import { Theme, useMediaQuery } from '@mui/material';
import { CSSProperties } from 'react';

export function responsiveStyles() {
  const divStyles: CSSProperties = {
    backgroundColor: '#FAFAFA',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '175px, 42px, 64px, 42px',
  };

  const isXSScreen = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'sm'));
  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    padding: '0',
    width: isXSScreen ? '285px' : '435px',
    height: 'auto',
    marginTop: '20px',
  };
  const typographyStyles = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.125rem',
    textTransform: 'uppercase',
    color: '#666666',
  };

  const ty2Styles = {
    fontFamily: 'EV_Head_bd',
    fontSize: '32px',
    fontWeight: '700',
    lineHeight: '40px',
    color: '#262626',
  };
  const evBtnStyles = {
    fontFamily: 'EV_Text_rg',
    textTransform: 'none',
    backgroundColor: '#C80000',
    '&:hover': {
      backgroundColor: '#910F05',
    },
    width: '100%',
    maxHeight: '40px',
  };
  const gridStyles = {
    gap: { xs: '20px', sm: '-10px' },
    marginLeft: '10%',
    height: '28px',
    marginBottom: { xs: '29%', sm: '25%', md: '10%', lg: '8%' },
    width: '75%',
    order: 3,
  };
  const ty1Styles = { fontFamily: 'EV_Text_rg', fontWeight: '400', fontSize: '16px', color: '#404040' };
  return { gridStyles, ty1Styles, ty2Styles, typographyStyles, evBtnStyles, containerStyles, divStyles };
}
