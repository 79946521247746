import { credentialsStore } from '../components/Login/credentialsStore';
import { fetchEventSource } from '@microsoft/fetch-event-source';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetchEventSourceWithAuth(url: string, options: any) {
  const authToken = credentialsStore.retrieveBasicAuthCredentials();

  options.headers = {
    ...options.headers,
    Authorization: `Basic ${authToken}`,
  };

  return fetchEventSource(url, options);
}
