import React, { useState } from 'react';
import { Container, Typography, TextField, Button, CssBaseline, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { Hit } from './Hit';
import logo from '../images/ai.svg';
import benifit from '../images/b.svg';
import benifit2 from '../images/b1.svg';
import benifit3 from '../images/b2.svg';
import { responsiveStyles } from './styles/WelcomeStyles';
import { credentialsStore } from '../components/Login/credentialsStore';
const customTheme = createTheme({
  palette: {
    primary: {
      main: '#EB0A0A',
    },
  },
});
const WelcomePage: React.FC = () => {
  const navigate = useNavigate();
  const [enteredId, setEnteredId] = useState('');

  const handleButtonClick = async () => {
    try {
      let hitArray: Hit[] = [];
      const data = { enteredId: enteredId };
      const response = await fetch('/api/algolia', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${credentialsStore.retrieveBasicAuthCredentials()}`,
        },
        body: JSON.stringify(data),
      });
      const hits = await response.json();
      hitArray = hits;
      if (enteredId !== '' && hitArray.length != 0) {
        navigate('/expose', { state: { enteredId: enteredId } });
      } else {
        alert('Please enter a valid property ID');
      }
    } catch (e) {
      alert('Please enter a valid property ID or try again later!');
    }
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleButtonClick();
    }
  };
  const { gridStyles, ty1Styles, divStyles, containerStyles, typographyStyles, ty2Styles, evBtnStyles } =
    responsiveStyles();

  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <div style={divStyles}>
        <Header />
        <Container maxWidth="sm" sx={containerStyles}>
          <Grid container direction="column" alignItems="center" justifyContent="center" spacing={1.5}>
            <img src={logo} style={{ height: '48px', width: '48px', opacity: '0.6', marginBottom: '12px' }} />
            <Typography sx={typographyStyles} gutterBottom>
              Supported by AI
            </Typography>
          </Grid>

          <Typography align="center" gutterBottom sx={ty2Styles}>
            Exposé Co-Pilot
          </Typography>
          <Typography variant="body1" align="center" gutterBottom sx={ty1Styles}>
            Start using the Co-Pilot to draft ideas for Expose-Texts by entering the E&V Property ID like W-0123ABC.
          </Typography>
          <TextField
            label="Enter property ID (EV ID)"
            variant="outlined"
            fullWidth
            margin="normal"
            value={enteredId}
            sx={{ paddingBottom: 2 }}
            onKeyPress={handleKeyPress}
            size={'small'}
            onChange={e => setEnteredId(e.target.value)}
            data-testid="property-id-input"
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disableElevation={true}
            onClick={handleButtonClick}
            sx={evBtnStyles}
            data-testid="get-started-button">
            Getting Started
          </Button>
        </Container>
        <Grid container direction="row" alignItems="center" justifyContent="center" sx={gridStyles}>
          <Grid item xs={7} sm={3} xl={2.5}>
            <img src={benifit} alt="benefit" style={{ width: '100%', height: 'auto' }} />
          </Grid>
          <Grid item xs={7.5} sm={3.5} xl={2.59}>
            <img src={benifit2} alt="benefit2" style={{ width: '100%', height: 'auto' }} />
          </Grid>
          <Grid item xs={6.5} sm={2.7} xl={2.4}>
            <img src={benifit3} alt="benefit3" style={{ width: '100%', height: 'auto' }} />
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
};
export default WelcomePage;
