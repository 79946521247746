import React from 'react';

import { Provider } from 'react-redux';
import { reduxStore } from './redux/store';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './theme';

export const Providers = (props: React.PropsWithChildren) => {
  return (
    <Provider store={reduxStore}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </Provider>
  );
};
