export const inputStyles = {
  marginBottom: '0',
  display: 'flex',
  padding: '24px',
  color: 'var(--text-secondary, #777)',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24.5px',
  letterSpacing: '0.42px',
  border: '0px solid #bdbdbd',
  borderRadius: '4px',
  overflow: 'auto',
  background: 'white',
};
export const paperStyles = {
  background: '#FAFAFA',
  padding: '4px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '7px',
  paddingBottom: '7px',
  boxShadow: '0',
};
export const iconStyles = { height: '24px', width: '24px', paddingLeft: '0px', paddingTop: '0px', paddingRight: '0px' };
export const refreshButtonStyles = (isDisabled: boolean) => ({
  color: 'black',
  borderColor: 'black',
  '&:hover': {
    backgroundColor: 'darkgrey',
  },
  textTransform: 'none',
  marginRight: '10px',
  fontWeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  opacity: isDisabled ? 0.5 : 1,
});
export const copyButtonstyles = (copiedHeadline: boolean) => ({
  color: 'var(--text-primary, #444)',
  borderColor: 'grey',
  '&:hover': {
    backgroundColor: copiedHeadline ? 'grey' : 'grey',
  },
  textTransform: 'none',
  marginLeft: 'auto',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '175%',
  letterSpacing: '0.42px',
  display: 'flex',
  alignItems: 'center',
});
export const typoStyles = {
  fontFamily: 'EV_Text_rg',
  fontWeight: 'bold',
  color: '#444',
  height: '28px',
  width: '66px',
  lineHeight: '28px',
  letterSpacing: '1px',
};
export const gridStyles = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginBottom: '40px',
};
export const gridStyle = { display: 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: '32px' };
