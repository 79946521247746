export const TextFieldStyles = {
  '& label.Mui-focused': {
    color: '#777777',
  },
  '& .MuiInput-underline:after': {
    border: '1px solid #777777',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #777777',
    },
    '&:hover fieldset': {
      border: '1px solid #777777',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #777777',
    },
  },
};

export const circularStyle = {
  position: 'absolute',
  left: '8px',
  top: '50%',
  marginTop: '-12px',
  color: '#fff',
};
export const grid3Styles = {
  display: 'flex',
  justifyContent: 'center',
  position: 'sticky',
  bottom: '0px',
  background: '#ffffff',
};
export const subHeadingStyles = {
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '175%',
  letterSpacing: '0.48px',
  display: 'flex',
  alignItems: 'center',
  color: '#444444',
};
export const typographyStyles = {
  marginTop: '16px',
  flex: '1 0 0',
  alignSelf: 'stretch',
  color: 'var(--text-secondary, #777)',
  fontFamily: 'EV_Text_rg, sans-serif',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '175%',
  letterSpacing: '0.42px',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const chipsStyles = (button: any) => ({
  fontSize: '12px',

  bgcolor: button.state ? '#F0F0F0' : '#ffffff',
  borderRadius: '20px',
  textTransform: 'none',
  color: '#444444',
  border: button.state ? '1px solid #F0F0F0' : '1px solid #444444',
  '&:hover': {
    bgcolor: '#ffffff',
    color: '#444444',
    boxShadow: 'none',
    border: '1px solid #444444',
  },
  '&.Mui-selected': {
    bgcolor: '#F0F0F0',
    color: '#444444',
    border: '1px solid #444444',
  },
  boxShadow: 'none',
});
export const dividerStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderRadius: '20px',
  border: '1px solid lightgrey',
};
export const gridStyles = {
  display: 'flex',
  justifyContent: 'left',
  gap: '8px',
  flexWrap: 'wrap',
  marginLeft: '-0.40%',
  paddingBottom: '8px',
  paddingTop: '8px',
};
export const infoButtonStyles = {
  display: 'flex',
  width: '258px',
  height: '36px',
  padding: '0px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
  border: '1px solid var(--shade-grey-1, #444)',
  backgroundColor: '#FFFFFF',
  color: '#000000',
  '&:hover': {
    backgroundColor: '#777777',
    color: '#FFFFFF',
  },
  textTransform: 'none',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '175%' /* 24.5px */,
  letterSpacing: '0.42px',
  mt: 0,
  boxShadow: 'none',
  elevation: 'none',
};
export const addressStyles = {
  color: 'var(--text-primary, #444)',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '175%',
  letterSpacing: '0.42px',
};
export const genButtonStyles = {
  mt: 0,
  backgroundColor: '#C80000',
  '&:hover': {
    backgroundColor: '#910F05',
  },
  textTransform: 'none',
  position: 'relative',
};
export const resetButtonStyles = {
  backgroundColor: '#ffffff',
  borderColor: '#444444',
  color: '#444444',
  '&:hover': {
    borderColor: '#666666',
  },
  textTransform: 'none',
};
export const stickyfooterStyles = {
  display: 'flex',
  width: '120%',
  position: 'sticky',
  bottom: '0px',
  background: '#fafafa',
  padding: '14px',
  paddingLeft: '40px',
  paddingRight: '40px',
  borderTop: '1px solid #e0e0e0',
  zIndex: 1,
};
