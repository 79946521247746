import axios from 'axios';
import { credentialsStore } from './credentialsStore';

export const configureAxios = () => {
  axios.interceptors.request.use(
    config => {
      const token = credentialsStore.retrieveBasicAuthCredentials();
      if (token !== null) {
        config.headers['Authorization'] = 'Basic ' + token;
      }
      return config;
    },
    error => {
      Promise.reject(error);
    }
  );
};
