import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Header from './Header';
import TextArea from './TextArea';
import PropertyForm from './PropertyForm';
import Grid from '@mui/material/Grid';
import { Divider, Theme, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ErrorTextArea from './ErrorTextArea';
import MobileHeader from './MobileHeader';
import { useResponsiveStyles } from './styles/MainStyles';

function Main() {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isErrorMain, setIsErrorMain] = useState(false);
  const location = useLocation();
  const enteredId = location.state?.enteredId || '';
  const [isFirstTime, setIsFirstTime] = useState(true);
  const setResponsiveness = useMediaQuery((theme: Theme) => theme.breakpoints.between(600, 660));
  const setSx = useMediaQuery((theme: Theme) => theme.breakpoints.between(600, 1000));
  const isXSScreen = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'sm'));
  const showMobileHeader = useMediaQuery((theme: Theme) => theme.breakpoints.between('xs', 'sm'));
  const [showMobileTextArea, setShowMobileTextArea] = useState(false);
  const handleGenerateClick = () => {
    setShowMobileTextArea(true);
  };
  const handleBackButtonClick = () => {
    setShowMobileTextArea(false);
  };
  const { gridStyle, gridPadding, dividerStyles, containerStyles } = useResponsiveStyles();
  const textarea = (
    <TextArea isFirstTime={isFirstTime} enteredId={enteredId} isDisabled={isDisabled} setIsDisabled={setIsDisabled} />
  );
  const propform = (
    <PropertyForm
      setIsFirstTime={setIsFirstTime}
      enteredEVID={enteredId}
      onGenerateClick={handleGenerateClick}
      isErrorMain={isErrorMain}
      setIsErrorMain={setIsErrorMain}
      isDisabled={isDisabled}
      setIsDisabled={setIsDisabled}
    />
  );
  return (
    <>
      <CssBaseline />
      {showMobileHeader ? (
        <MobileHeader
          showMobileTextArea={showMobileTextArea}
          onBackButtonClick={handleBackButtonClick}
          isDisabled={isDisabled}
        />
      ) : (
        <Header />
      )}
      {showMobileHeader ? (
        <Grid
          container
          spacing={2}
          style={gridPadding}
          sx={{ backgroundColor: showMobileTextArea ? '#fafafa' : '#ffffff' }}>
          <Grid item xs={12} sm={6}>
            <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
              <Box component="div" display={showMobileTextArea ? 'block' : 'none'}>
                {textarea}
              </Box>
              <Box
                component="div"
                display={!showMobileTextArea ? 'block' : 'none'}
                sx={{ mt: '25px', mb: isXSScreen ? '-33px' : undefined }}>
                {propform}
              </Box>
            </Container>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} sx={{ marginBottom: '0px', marginLeft: '-20px' }}>
          <Grid item xs={12} sm={setSx ? (setResponsiveness ? 5.9 : 5.5) : 4}>
            <Container component="main" maxWidth={false} sx={{ mb: 4, padding: 0 }}>
              <Box
                component="main"
                sx={{
                  width: '100%',
                  mt: '24px',
                  mb: '-35px',
                  padding: 0,
                }}>
                {propform}
              </Box>
            </Container>
          </Grid>
          <Grid item xs={12} sm={setSx ? (setResponsiveness ? 6.1 : 6.5) : 8} sx={gridStyle}>
            <Divider orientation="vertical" variant="middle" flexItem sx={dividerStyles} />
            <Container style={containerStyles} component="main" sx={{ mb: 4, mt: '32px' }}>
              {isErrorMain ? <ErrorTextArea /> : textarea}
            </Container>
          </Grid>
        </Grid>
      )}
    </>
  );
}
export default Main;
