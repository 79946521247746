import React from 'react';
import error from '../images/Error.png';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export default function ErrorTextArea() {
  return (
    <Grid item xs={12} sx={{ height: '0' }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{ marginTop: '25%' }}>
        <img
          src={error}
          style={{
            marginTop: '42px',
            width: '96px',
            height: '96px',
          }}
          alt="Error"
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{
            marginTop: '24px',
            marginBottom: '144px',
          }}>
          <Typography
            style={{
              color: '#444',
              textAlign: 'center',
              fontSize: '28px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '150%',
              letterSpacing: '0.56px',
              marginBottom: '8px',
            }}>
            An error has occurred
          </Typography>
          <Typography
            style={{
              color: 'var(--text-secondary, #777)',
              textAlign: 'center',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '175%' /* 28px */,
              letterSpacing: '0.48px',
            }}>
            Apparently we could not load the object details. Please check the entered EV Property ID or try again later.
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}
