import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import axios from 'axios';
import { credentialsStore } from './credentialsStore';

function Login() {
  const [loginUsername, setLoginUsername] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginUsername(event.target.value);
  };

  const handleLoginPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginPassword(event.target.value);
  };

  const handleLoginFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loginUsername.trim() === '') {
      setUsernameError(true);
      return;
    }

    setUsernameError(false);
    setEmailError(false);

    const loginToBackend = async () => {
      try {
        const response = await axios.post('/api/login', undefined, {
          headers: {
            Authorization: `Basic ${credentialsStore.encodeBasicAuthCredentials(loginUsername, loginPassword)}`,
          },
        });
        console.log(response.data);
        return response;
      } catch (error) {
        alert('Wrong credentials!');
      }
    };
    loginToBackend()
      .then(response => {
        if (response?.status === 200) {
          credentialsStore.storeBasicAuthCredentials(loginUsername, loginPassword);
          window.location.reload();
        } else {
          console.error('Login failed');
        }
      })
      .catch(error => {
        console.error('Error sending data to backend:', error);
      });
  };

  const handleProfileClick = () => {
    window.location.reload();
  };

  return (
    <div className="container">
      <section id="formHolder">
        <div className="row">
          <div className="col-sm-6 brand">
            <a href="#" className="logo">
              E&V <span>.</span>
            </a>
            <div className="heading">
              <h2>Engel & Völkers</h2>
              <p>Your Right Choice</p>
            </div>
            <div className="success-msg">
              <p>Great! You are one of our members now</p>
              <a href="#" className="profile" onClick={handleProfileClick}>
                Your Profile
              </a>
            </div>
          </div>
          <div className="col-sm-6 form">
            <div className={`login form-peice`}>
              <form className="login-form" onSubmit={handleLoginFormSubmit}>
                <div className={`form-group ${usernameError ? 'hasError' : ''}`}>
                  <label htmlFor="loginUsername">Username</label>
                  <input
                    name="loginUsername"
                    id="loginUsername"
                    required
                    value={loginUsername}
                    onChange={handleUsernameChange}
                  />
                  {usernameError && <span className="error">Please type your full name</span>}
                </div>
                <div className={`form-group ${emailError ? 'hasError' : ''}`}>
                  <label htmlFor="loginPassword">Password</label>
                  <input
                    type="password"
                    name="loginPassword"
                    id="loginPassword"
                    required
                    value={loginPassword}
                    onChange={handleLoginPasswordChange}
                  />
                  {emailError && <span className="error">Please enter a valid email address</span>}
                </div>
                <div className="CTA">
                  <input type="submit" value="Login" id="login-button" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <p>ENGEL & VÖLKERS</p>
      </footer>
    </div>
  );
}

export default Login;
